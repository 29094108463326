import { Switch, FormControlLabel } from "@mui/material";

import type { WidgetProps } from "@rjsf/utils";
import type { ChangeEvent, FC } from "react";
import styled from "@emotion/styled";
import { HelpText } from "@/framework/KioForm/common/HelpText";

const Row = styled.div`
  display: flex;
  align-items: center;
  min-width: fit-content;
  height: 100%;
  margin-right: -16px;
  .MuiFormHelperText-root {
    margin-inline: auto;
  }
  #switchLabel {
    user-select: none;
  }
`;

export interface SwitchWidgetProps extends WidgetProps {
  value: boolean;
  trueText?: string;
  falseText?: string;
}

const SwitchWidget: FC<SwitchWidgetProps> = ({
  onChange,
  value = false,
  color = "primary",
  disabled,
  label,
  required,
  readonly,
  uiSchema,
}) => {
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.checked);
  };
  const switchLabel = uiSchema?.["ui:label"] || uiSchema?.["ui:title"] || label;
  return (
    <Row>
      <FormControlLabel
        control={
          <Switch
            color="primary"
            onChange={onChangeHandler}
            checked={value}
            value={value}
            disabled={disabled || readonly}
            required={required}
            aria-required={required}
            aria-disabled={disabled}
            aria-readonly={readonly}
            inputProps={{ "aria-labelledby": "switchLabel" }}
          />
        }
        label={
          <span id="switchLabel">
            {switchLabel}
            <HelpText uiSchema={uiSchema} />
          </span>
        }
      />
    </Row>
  );
};

export default SwitchWidget;

import React, { type FC, useRef } from "react";
import { memo, useEffect, useState } from "react";
import type { Status } from "@googlemaps/react-wrapper";

import { FireplaceSharp, RemoveCircleOutline, WrongLocation } from "@mui/icons-material";
import { styled } from "@mui/material";
import type { Location } from "@/declarations/models/Location";
import Settings from "@/Settings";
import {
  AdvancedMarker,
  ControlPosition,
  Map,
  MapControl,
  type MapMouseEvent,
  useMap,
} from "@vis.gl/react-google-maps";
import { PlacePicker } from "@googlemaps/extended-component-library/react";
import LatLng = google.maps.LatLng;
import { useTranslation } from "react-i18next";
import { IconButton as MapIconButton } from "@googlemaps/extended-component-library/react";

export interface LocationPickerProps {
  location: Location;
  onChange?: (location: Location) => void;
}
const Remove = memo(styled(RemoveCircleOutline)`
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  background: white;
  border-radius: 50%;
  cursor: pointer;

  svg {
    height: 16px;
    width: 16px;
  }
`);

const Container = styled("div")`
  display: flex;
`;

const InputContainer = styled("div")`
  display: flex;
  gap: 5px;
  margin-bottom: 15px;
  max-width: 80%;
  flex-wrap: wrap;
  font: 14px, var(--gmpx-font-family-base, "Google Sans Text", sans-serif);
`;

const CoordinatesContainer = styled("div")`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  width: 100%;
`;

const CoordinateInput = styled("input")`
  background-color: #fff;
  border: 1px solid #80868b;
  border-radius: 4px;
  color: #212121;
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  height: 41px;
  padding: 10px;
  flex-grow: 1;
`;

const MapContainer = styled("div")`
  width: 100%;
  min-width: 300px;
  min-height: 450px;
  position: relative;

  // Hack to prevent MapControl to overlap zoom buttons.
  // visgl/react-google-maps overrides all styles for MapControl
  div:has(> .inputContainerHack) {
    max-width: 32vw; // arbitrary value, seems to work for most cases
  }
`;

const CustomPlacePicker = styled(PlacePicker)`
  width: 100%;
  min-width: 50ch;
`;

const CustomMapIconButton = styled(MapIconButton)`
  margin-right: 10px;
  --gmpx-color-primary: darkred;
`;

const LocationPicker: FC<LocationPickerProps> = ({ location, onChange }) => {
  const pickerRef = useRef<PlacePicker>(null);
  const [place, setPlace] = useState<google.maps.places.PlaceResult | null>(null);
  const [placePickerKey, setPlacePickerKey] = useState<number>(0);
  const { t } = useTranslation();

  const isLocationAValidPosition = (): boolean => {
    if (!location?.lat || !location?.lng) return false;
    if (Number.isNaN(location.lat) || Number.isNaN(location.lng)) return false;

    return true;
  };

  const [position, setPosition] = useState<LatLng | null>(isLocationAValidPosition() ? location : null);

  const default_position = {
    lat: isLocationAValidPosition() ? location?.lat : Settings.LOCATION_PICKER_DEFAULT_COORDS.lat,
    lng: isLocationAValidPosition() ? location?.lng : Settings.LOCATION_PICKER_DEFAULT_COORDS.lng,
  };

  const map = useMap("kioMap");

  const mapOnClick = (evt: MapMouseEvent): void => {
    if (evt?.detail?.latLng) {
      const newPosition = evt.detail.latLng;
      setPosition(newPosition);
      setPlacePickerKey((prevState) => prevState + 1);
      map?.panTo(newPosition);
      onChange?.({ ...newPosition });
    }
  };

  const getStepSizeByZoomLevel = (zoomLevel: number) => {
    //16 0.0001
    const decimalPlaces = Math.floor(zoomLevel / 5) + 1;
    return Number(`1e-${decimalPlaces}`);
  };

  const onCoordinateChangeLat = (evt: React.ChangeEvent<HTMLInputElement>) => {
    onCoordinateChange(evt, true);
  };

  const onCoordinateChangeLng = (evt: React.ChangeEvent<HTMLInputElement>) => {
    onCoordinateChange(evt, false);
  };

  const onCoordinateChange = (evt: React.ChangeEvent<HTMLInputElement>, isLat: boolean) => {
    let newPosition: LatLng = { ...position };
    if (isLat) newPosition.lat = Number(evt.target.value);
    else newPosition.lng = Number(evt.target.value);
    map?.panTo(newPosition);
    setPosition(newPosition);
    setPlacePickerKey((prevState) => prevState + 1);
    onChange?.({ ...newPosition });
  };
  const onPlaceChange = () => {
    const selectedPlace = pickerRef.current?.value;
    if (!selectedPlace) {
      setPlace(null);
    } else {
      setPlace(selectedPlace);
      if (selectedPlace.location) {
        const newPosition = {
          lat: selectedPlace.location.lat(),
          lng: selectedPlace.location.lng(),
        };

        setPosition(newPosition);
        onChange?.({ ...newPosition });
      }
      map?.panTo(selectedPlace.location);
      console.log(selectedPlace.location);
    }
  };

  const removeMarker = () => {
    setPlace(null);
    setPosition(null);
    setPlacePickerKey((prevState) => prevState + 1);
    onChange?.(undefined);
  };

  useEffect(() => {
    if (!location || !location?.lat || !location?.lng) {
      return;
    }
    map?.panTo(location);
    setPosition({ ...location });
  }, [location]);

  return (
    <Container>
      <MapContainer>
        <Map
          id={"kioMap"}
          defaultCenter={default_position}
          defaultZoom={10}
          mapId="KulturioAdminMapID"
          clickableIcons={false}
          streetViewControl={false}
          onClick={(evt) => {
            mapOnClick(evt);
          }}
        >
          {!!position?.lat && !!position?.lng && <AdvancedMarker position={position} />}
          {!!position?.lat && !!position?.lng && (
            <MapControl position={ControlPosition.RIGHT_CENTER}>
              <CustomMapIconButton icon="wrong_location" variant="filled" onClick={removeMarker}></CustomMapIconButton>
            </MapControl>
          )}
          <MapControl position={ControlPosition.BOTTOM_LEFT}>
            <InputContainer className="inputContainerHack">
              <CustomPlacePicker
                key={placePickerKey}
                ref={pickerRef}
                forMap="kioMap"
                country={["no", "sv"]}
                type={["museum", "name", "geometry", "formatted_address"]}
                label={t("components.locationPickerField.search")}
                placeholder={t("components.locationPickerField.search")}
                placeBias="IP_BIAS"
                onPlaceChange={onPlaceChange}
              />
              <CoordinatesContainer>
                <CoordinateInput
                  type={"number"}
                  label={t("generic.latitude")}
                  step={getStepSizeByZoomLevel(map?.getZoom() ?? 10)}
                  placeholder={t("generic.latitude")}
                  value={position?.lat ?? ""}
                  onChange={onCoordinateChangeLat}
                />
                <CoordinateInput
                  type={"number"}
                  step={getStepSizeByZoomLevel(map?.getZoom() ?? 10)}
                  placeholder={t("generic.longitude")}
                  label={t("generic.longitude")}
                  value={position?.lng ?? ""}
                  onChange={onCoordinateChangeLng}
                />
              </CoordinatesContainer>
            </InputContainer>
          </MapControl>
        </Map>
      </MapContainer>
    </Container>
  );
};

export default React.memo(LocationPicker);

import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Avatar } from "@mui/material";
import { useStore } from "@/Store";

export const CurrentUserAvatar: FC = () => {
  const { state } = useStore();
  const { t } = useTranslation("common");
  const name: string = state.user?.name || t("header.profile.anonymous") || "";
  const profileImageUrl = state.user?.image_url || "";

  return (
    <Avatar src={profileImageUrl}>
      {name
        .trim()
        .split(/\s+/)
        .map((n) => n[0].toUpperCase())
        .join("")}
    </Avatar>
  );
};

export default CurrentUserAvatar;

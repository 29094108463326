import styled from "@emotion/styled";
import { CenterFocusStrongOutlined, Close } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import type { DragEvent, FC, MouseEvent } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Styles from "@/assets/styles/Styles";
import { getCSSFilter } from "@/components/Image";
import type { FilterProps } from "@/components/MediaSelector/ColorFiltersModal";
import type Media from "@/declarations/models/Media";
import { calculateCoordinates } from "@/utils/geometry";

export interface FocalPointProps {
  x: number;
  y: number;
}

interface Filters {
  filters?: string;
}

export const FOCAL_POINT_ALLOWED_MEDIA_TYPES = ["video", "image", "dm"];
export const DEFAULT_FOCAL_POINT = { x: 50, y: 50 };

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    height: fit-content;
    max-height: calc(100vh - 64px);
    min-width: 600px;
    max-width: calc(100vw - 64px);
    justify-content: space-between;
    gap: 16px;
    .MuiAppBar-positionRelative {
      width: 100%;
      padding: 16px;
    }
  }
  .MuiAlert-root {
    width: calc(100% - 32px);
    padding: 0 8px;
    margin: 4px 16px;
    justify-content: flex-start;
    .MuiAlert-icon {
      margin: auto 8px auto 0;
    }
  }
  .MuiDialogTitle-root {
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 0;
    .MuiTypography-root {
      font-weight: 700;
      font-size: 18px;
    }
  }
  .MuiDialogActions-root {
    padding: 0 16px 16px;
  }
  .MuiDialogContent-root,
  .MuiList-root,
  .rjsf,
  .MuiAlert-icon,
  .noPadding {
    padding: 0;
  }
`;

const GridWrapper = styled.div`
  height: min(calc(100vh - 192px), 90vw, 1000px);
  width: min(calc(100vh - 192px), 90vw, 1000px);
  padding: 16px 24px 8px 16px;
  margin: -16px auto 0;
`;

const ImagesGrid = styled.div`
  display: grid;
  grid-template-columns: 45% 32.5% 22.5%;
  grid-template-rows: 45% 32.5% 22.5%;
  height: 100%;
  width: 100%;
  gap: 4px;
`;

const FocalImageContainer = styled.div`
  display: flex;
  max-height: 100%;
  max-width: 100%;
  position: relative;
  cursor: crosshair;
  user-select: none;
`;

const FocalImage = styled.img<Filters>`
  height: 100%;
  width: 100%;
  filter: ${(props) => props.filters};
`;

const FocalVideo = styled.video<Filters>`
  height: 100%;
  width: 100%;
  filter: ${(props) => props.filters};
`;

const ImageContainer = styled.div`
  display: flex;
  user-select: none;
  max-height: ${Styles.Dimensions.FOCAL_POINT_MODAL_MASTER_IMAGE_MAX_HEIGHT};
`;

const Image = styled(FocalImage)<FocalPointProps & Filters>`
  object-fit: cover;
  object-position: ${(props) => props.x}% ${(props) => props.y}%;
`;

const Video = styled(FocalVideo)<FocalPointProps>`
  object-fit: cover;
  object-position: ${(props) => props.x}% ${(props) => props.y}%;
`;

const FocalPointIndicator = styled.div<FocalPointProps>`
  display: flex;
  position: absolute;
  border-radius: 50%;
  width: 3.75rem;
  height: 3.75rem;
  border: 4px solid #fff;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
  transform: translateX(-50%) translateY(-50%);
  left: ${(props) => props.x || 50}%;
  top: ${(props) => props.y || 50}%;
  user-select: none;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.02);
  z-index: 1;
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TitleIconContainer = styled.div`
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  > .MuiSvgIcon-root {
    height: 16px;
  }
  > span {
    font-size: 0.875rem;
    line-height: 100%;
  }
`;

interface FocalPointModalProps {
  mediaItem: Media | null;
  originalFocalPoint: FocalPointProps;
  open: boolean;
  handleClose: () => void;
  onFocalPointChange?: (focalPointObj: FocalPointProps) => void;
  filters?: FilterProps;
}

const FocalPointModal: FC<FocalPointModalProps> = ({
  mediaItem,
  open,
  originalFocalPoint = DEFAULT_FOCAL_POINT,
  handleClose,
  onFocalPointChange,
  filters,
}) => {
  const { t } = useTranslation("common");
  const [currentFocalPoint, setCurrentFocalPoint] = useState(originalFocalPoint);
  const mediaType = mediaItem?.media_type || "image";

  const updateFocalPointPosition = (e: DragEvent<HTMLElement> | MouseEvent<HTMLElement>) => {
    const relativePercentagePosition = calculateCoordinates(e, 3);
    onFocalPointChange!(relativePercentagePosition);
    setCurrentFocalPoint(relativePercentagePosition);
  };

  const resetFocalPointPosition = () => {
    onFocalPointChange!(DEFAULT_FOCAL_POINT);
    setCurrentFocalPoint(DEFAULT_FOCAL_POINT);
  };

  const filterString = getCSSFilter(filters);

  return (
    <StyledDialog maxWidth={"xl"} open={open} onClose={handleClose}>
      <DialogTitle id="focalpointModal-title">
        <TitleRow>
          <Typography component={"h1"}>{t("components.MediaSelector.FocalPointModal.title")}</Typography>
          <TitleIconContainer>
            <CenterFocusStrongOutlined />
            <span>{`X${(currentFocalPoint.x / 100).toFixed(2)} Y${(currentFocalPoint.y / 100).toFixed(2)}`}</span>
          </TitleIconContainer>
        </TitleRow>
        <IconButton onClick={handleClose} className={"noPadding"}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <GridWrapper>
          <ImagesGrid>
            <FocalImageContainer>
              <FocalPointIndicator x={currentFocalPoint.x} y={currentFocalPoint.y} />
              {mediaType === "video" ? (
                <FocalVideo
                  draggable={false}
                  onClick={updateFocalPointPosition}
                  src={mediaItem?.src}
                  filters={filterString}
                />
              ) : (
                <FocalImage
                  draggable={false}
                  onClick={updateFocalPointPosition}
                  src={mediaItem?.src}
                  filters={filterString}
                />
              )}
            </FocalImageContainer>
            {[0, 1, 2, 3, 4, 5, 6, 7].map((el) => (
              <ImageContainer key={el + mediaType}>
                {mediaType === "video" ? (
                  <Video x={currentFocalPoint.x} y={currentFocalPoint.y} src={mediaItem?.src} filters={filterString} />
                ) : (
                  <Image x={currentFocalPoint.x} y={currentFocalPoint.y} src={mediaItem?.src} filters={filterString} />
                )}
              </ImageContainer>
            ))}
          </ImagesGrid>
        </GridWrapper>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetFocalPointPosition} variant={"outlined"}>
          {t("generic.reset")}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};
export default FocalPointModal;

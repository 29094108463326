import styled from "@emotion/styled";
import { Button } from "@mui/material";
import type { FieldProps } from "@rjsf/utils";
import React, { type FC, type MutableRefObject, useReducer } from "react";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import placeholderImage from "@/assets/img/placeholder_image_small.svg";
import type Document from "@/declarations/models/Document";
import type DocumentRelation from "@/declarations/models/DocumentRelation";
import { useEditorStore } from "@/EditorContextProvider";
import CollapseBar from "@/framework/KioForm/common/CollapseBar";
import KioTitle from "@/framework/KioForm/common/KioTitle";
import { useModal } from "@/framework/ModalManager/useModal";
import { useResolvePotentiallyLocalizedString } from "@/hooks/useResolvePotentiallyLocalizedString";
import SchemaField from "@rjsf/core/lib/components/fields/SchemaField";
import { List } from "@mui/icons-material";
import { getSchemaName } from "@/utils/schema";
import type Menu from "@/declarations/models/Menu";
import { useFetchApplicationMenu } from "@/hooks/useFetchApplicationMenu";
import { getValue } from "@/utils/obj";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const moveItem = (
  item: DocumentRelation,
  //formData: FieldProps["formData"],
  formDataRef: MutableRefObject<Record<string, any>[]>,
  onChange: FieldProps["onChange"],
  up = false
) => {
  if (!formDataRef.current || !Array.isArray(formDataRef.current)) return;
  const _tmp = [...formDataRef.current];
  const idx = _tmp.findIndex((_item) => _item.document_id === item.document_id);
  if (idx < 0) return;
  const t = _tmp.splice(idx, 1)?.[0];
  if (!t) return;
  if (up) {
    _tmp.splice(idx - 1, 0, t);
    formDataRef.current = [..._tmp];
    onChange?.([..._tmp]);
    return;
  }
  _tmp.splice(idx + 1, 0, t);
  formDataRef.current = [..._tmp];
  onChange?.([..._tmp]);
};

const expandedStateReducer = (state: any, action: any) => {
  switch (action.type) {
    case "OPEN":
      return { ...state, [action.id]: true };
    case "CLOSE":
      return { ...state, [action.id]: false };
    default:
      // @ts-ignore
      return { ...state, [action.id]: !state?.[action.id] ?? true };
  }
};

const StateRelationPickerField: FC<FieldProps> = ({
  uiSchema,
  formContext,
  formData,
  onChange,
  idSchema,
  schema,
  errorSchema,
  registry,
}) => {
  const { t } = useTranslation("common");
  const { state: editorState, addDocumentIds } = useEditorStore();
  const schemaId = uiSchema?.["ui:options"]?.schemaId;
  const schemaIdsRaw: string[] | null | undefined = uiSchema?.["ui:options"]?.schemaIds;
  const enableContent = uiSchema?.["ui:options"]?.enableContent;
  const getLocalizedString = useResolvePotentiallyLocalizedString(formContext.selectedLocale);
  const title = formData?.title || "";
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [expandedState, expandedDispatch] = useReducer(expandedStateReducer, {});
  const formDataRef = useRef<Record<string, any>[]>([]);
  const appMenus = useFetchApplicationMenu();

  const schemaIds = useMemo(() => {
    if (!schemaIdsRaw?.length) {
      return [schemaId];
    }
    try {
      return schemaIdsRaw.map((id: string) => Number(id));
    } catch (err) {
      console.error(err);
    }
  }, [schemaId, schemaIdsRaw]);

  useEffect(
    () => () => {
      if (timeoutRef.current !== null) clearTimeout(timeoutRef.current);
    },
    []
  );
  useEffect(() => {
    if (Array.isArray(formData) && formData.length && formDataRef.current.length === 0) {
      formDataRef.current = formData;
    }
  }, [formData]);

  useEffect(() => {
    if (Array.isArray(formDataRef.current))
      addDocumentIds(formDataRef.current.map((item: DocumentRelation) => item.document_id));
    else if (formData && Object.prototype.hasOwnProperty.call(formData, "document_id"))
      addDocumentIds([formDataRef.current.document_id]);
  }, [addDocumentIds, formData]);

  const deleteItem = useCallback(
    (item: DocumentRelation) => {
      if (!formDataRef.current || !Array.isArray(formDataRef.current)) return;
      formDataRef.current = [...formDataRef.current.filter((_item) => _item.document_id !== item.document_id)];
      onChange?.([...formDataRef.current]);
    },
    [formData, onChange, formDataRef]
  );

  const getMoveFn = useCallback(
    (item: DocumentRelation, moveUp = false): (() => void) =>
      () => {
        moveItem(item, formDataRef, onChange, moveUp);
      },
    [formDataRef, onChange]
  );

  const isMoveDisabled = useCallback(
    (item: DocumentRelation, moveUp = false): boolean => {
      if (!formDataRef.current || !Array.isArray(formDataRef.current)) return false;
      const idx = formDataRef.current.findIndex((_item) => _item.document_id === item.document_id);
      if (!moveUp && idx >= formDataRef.current.length - 1) return true;
      return moveUp && idx <= 0;
    },
    [formDataRef]
  );

  const onSelect = useCallback(
    (doc: Document) => {
      if (doc.id) {
        if (!Array.isArray(formData)) {
          //timeoutRef.current = setTimeout(() => {
          formDataRef.current = [{ document_id: doc.id }];
          onChange?.([...formDataRef.current]);
          timeoutRef.current = null;
          //});
          addDocumentIds([doc.id]);
        } else if (!formDataRef.current.some((item: DocumentRelation) => item.document_id === doc.id)) {
          //timeoutRef.current = setTimeout(() => {
          formDataRef.current = [...formDataRef.current, { document_id: doc.id }];
          onChange?.([...formDataRef.current]);
          timeoutRef.current = null;
          //});
          addDocumentIds([doc.id]);
        }
      }
    },
    [addDocumentIds, formData, onChange]
  );

  const mapper = useCallback(
    (doc: Document) => ({
      key: String(doc.id),
      title: title || doc.internal_title || getLocalizedString(doc.title) || "*",
      subTitle: getSchemaName(doc, getLocalizedString, appMenus),
      infoText: t(`status.${doc.status}`),
      chipContent: getValue(doc, "content.tags.internal"),
      imageURL: doc.media_data?.thumbnail_url || undefined,
      updatedAt: doc.updated_at,
      updatedBy: doc.updated_by,
    }),
    [getLocalizedString, t, title, appMenus]
  );

  const selectedItems = useMemo(() => {
    return formDataRef.current?.map((item: DocumentRelation) => item?.document_id.toString()) || [];
  }, [formDataRef.current]);

  const onContentChange = (content: any, index: number) => {
    const copyOfChangedItem = { ...formDataRef.current[index] };

    formDataRef.current = [
      ...formDataRef.current.slice(0, index),
      { ...copyOfChangedItem, content: { ...copyOfChangedItem?.content, ...content } },
      ...formDataRef.current.slice(index + 1),
    ];

    onChange([...formDataRef.current]);
  };

  const modal = useModal("documentListModalContent", {
    mapper,
    onSelect,
    schemaIds,
    selectedItems,
    selectedLanguage: formContext.selectedLocale,
  });

  const content = schema?.items?.["properties"]?.["content"];
  return (
    <Container>
      <KioTitle title={title} level={2} />
      {formDataRef.current?.map((item: DocumentRelation, idx: number) => {
        const relation = editorState.documentRelations?.find((rel) => rel.document_id == item.document_id);

        const isFromSharedInstance =
          !!relation?.application_instance_id &&
          relation?.application_instance_id !== formContext.applicationInstanceId;
        return (
          <CollapseBar
            key={`${relation?.reference_key}-${item.document_id}--${idx}--${idSchema.$id}`}
            expanded={expandedState?.[`${relation?.reference_key}-${item.document_id}`]}
            setExpanded={() => {
              expandedDispatch({ id: `${relation?.reference_key}-${item.document_id}`, type: "TOGGLE" });
            }}
            enableMovable
            disableExpansion={!enableContent}
            onDelete={() => {
              deleteItem(item);
            }}
            onMoveUp={getMoveFn(item, true)}
            onMoveDown={getMoveFn(item, false)}
            moveUpButtonDisabled={isMoveDisabled(item, true)}
            moveDownButtonDisabled={isMoveDisabled(item, false)}
            enableDelete
            title={relation?.internal_title || getLocalizedString(relation?.title) || "*"}
            titleAddon={relation?.status ? `(${t(`status.${relation.status}`)})` : undefined}
            thumbnailImageSrc={relation?.media?.thumbnail_src || placeholderImage}
            isFromSharedInstance={isFromSharedInstance}
            sharedInstanceId={relation?.application_instance_id}
            selectedLocale={formContext.selectedLocale}
          >
            {enableContent && content && (
              <SchemaField
                readonly={false}
                required={false}
                disabled={false}
                autofocus={false}
                schema={content}
                uiSchema={uiSchema?.["content"]}
                idSchema={idSchema?.["content"]}
                formData={formData?.[idx]?.["content"]}
                errorSchema={errorSchema?.["content"]}
                registry={registry}
                onChange={(content) => {
                  onContentChange(content, idx);
                }}
                onBlur={() => {}}
                onFocus={() => {}}
                formContext={formContext}
                name={"test" + idx}
              />
            )}
          </CollapseBar>
        );
      })}
      <Button
        variant="outlined"
        color="primary"
        onClick={modal.open}
        sx={{ margin: "16px auto 0 0" }}
        startIcon={<List />}
      >
        {t("components.relationField.findDocumentLabel")}
      </Button>
    </Container>
  );
};

export default memo(StateRelationPickerField);
